// external dependencies
import React from 'react';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

// gatsby dependencies
import { GatsbyImage } from 'gatsby-plugin-image';

// internal dependencies
import Hero from '../../components/shared/Hero';
import Cta from '../../components/shared/Cta';
import SEO from '../../components/SEO';
import Section from '../../components/shared/Section';
import Button from '../../components/shared/Button';
import EnhancedGrid from '../../components/shared/EnhancedGrid';
import { useStaticQuery, graphql } from 'gatsby';

const StyledSection = styled(Section)`
  h5 {
    color: ${(props) => props.theme.colors.blue};
    text-align: center;
    margin-bottom: 15px;
  }

  button {
    margin: auto;
  }
`;
const StyledImg = styled(GatsbyImage)` 
  height: 400px;
  width: auto;
  margin: 25px auto;

  img {
    object-fit: contain !important;
    box-shadow: ${(props) => props.theme.effects.boxShadow};
  }
`;

// component definition
const BrochuresPage = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulBrochuresPage {
        metaDescription
        pageTitle
        hero {
          buttonLink
          buttonText
          headline
          supertext
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        brochures {
          buttonText
          title
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
          file {
            file {
              url
            }
            title
          }
        }
        ctaSection {
          email
          headline
          phoneNumber
          supertext
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
  `);
  return (
    <>
      <SEO
        title={data.contentfulBrochuresPage.pageTitle}
        description={data.contentfulBrochuresPage.metaDescription}
      />
      <Hero headline='Our Brochures' short />
      <StyledSection>
        <EnhancedGrid
          container
          wrap='wrap'
          justifyContent='space-evenly'
          align='flex-start'
        >
          {data.contentfulBrochuresPage.brochures.map((item, i) => (
            <Grid
              key={i}
              item
              xs={12}
              sm={6}
              md={4}
              align='flex-start'
              style={{ marginBottom: '25px' }}
            >
              <StyledImg image={item.image.gatsbyImageData} />
              <h5>{item.title}</h5>
              <Button href={item.file.file.url}>{item.buttonText}</Button>
            </Grid>
          ))}
        </EnhancedGrid>
      </StyledSection>
      <Cta
        ctaSupertext={data.contentfulBrochuresPage.ctaSection.supertext}
        ctaHeadline={data.contentfulBrochuresPage.ctaSection.headline}
        phoneNumber={data.contentfulBrochuresPage.ctaSection.phoneNumber}
        email={data.contentfulBrochuresPage.ctaSection.email}
        ctaImage={data.contentfulBrochuresPage.ctaSection.image}
      />
    </>
  );
};

export default BrochuresPage;
